import React from 'react';
import { useDispatch } from 'react-redux';

import {
  MenuAvatarTypes,
  MenuUtilities,
  MenuUtilitiesItemsOption,
  TopMenu as TopMenuPrimitive,
} from '@arcotech-services/iris-react';

import { userActions } from '~/actions/v2';

import { BrandDomain } from '~/enum';
import { useGetUserBrands, useReduxState } from '~/hooks';
import { logout } from '~/actions/auth';
import { Constants } from '~/config/env';

const TopMenu = () => {
  const dispatch = useDispatch();

  const {
    auth: { me: user },
    brand: { brandConfig: brandInfos },
    config: { configPortal },
  } = useReduxState();

  const { data: userBrands } = useGetUserBrands();

  const schoolBrand = user?.school?.schoolBrands?.find((schoolBrand) => schoolBrand?.idBrand === brandInfos?.id);

  const userBrandsWithoutBrandActive = userBrands?.filter((brand) => brand?.id !== brandInfos?.id);

  const userBrandsFilter = () => {
    const hasPleno = userBrandsWithoutBrandActive?.some((brand) => brand.domainUrl === BrandDomain.PROGRAMA_PLENO);
    const hasEi = userBrandsWithoutBrandActive?.some((brand) => brand.domainUrl === BrandDomain.ESCOLA_DA_INTELIGENCIA);

    if (hasPleno && hasEi) {
      const excludeDomain =
        brandInfos?.domainUrl === BrandDomain.ESCOLA_DA_INTELIGENCIA
          ? BrandDomain.PROGRAMA_PLENO
          : BrandDomain.ESCOLA_DA_INTELIGENCIA;

      return userBrandsWithoutBrandActive?.filter((brand) => brand.domainUrl !== excludeDomain);
    }

    return userBrandsWithoutBrandActive;
  };

  const iconBrand = (brandDomain?: string): any => {
    switch (brandDomain) {
      case BrandDomain.PROGRAMA_PLENO:
        return 'pleno';
      case BrandDomain.NAVE:
        return 'nave-a-vela';
      case BrandDomain.MARALTO:
        return 'maralto-edicoes';
      case BrandDomain.ESCOLA_DA_INTELIGENCIA:
        return 'escola-da-inteligencia';
      case BrandDomain.GENIO:
        return 'genio-das-financas';
      case BrandDomain.MESTRE:
        return 'mestre-dos-mestres';
      case BrandDomain.PGS:
        return 'pgs';
      default:
        return '';
    }
  };

  const utilityOptions: MenuUtilitiesItemsOption[] = [
    {
      label: 'Outros produtos',
      icon: 'DialpadSquare',
      hide: userBrandsFilter()?.length === 1,
      shouldShowIn: ['desktop'],
      submenu: userBrandsFilter()?.map((userBrand) => ({
        icon: iconBrand(userBrand?.domainUrl),
        isLogo: true,
        label: userBrand?.name ?? '',
        target: '_blank',
        onClick: () => dispatch(userActions.changeActiveBrand(userBrand, user?.profileType as number)),
      })),
    },
    {
      href: configPortal?.faq ?? '',
      target: '_blank',
      label: 'Ajuda',
      icon: 'HelpCircle',
      hide: !configPortal?.faq,
      shouldShowIn: ['desktop'],
    },
  ];

  const profile: MenuAvatarTypes = {
    email: user?.email ?? '',
    image: user?.profilePictureUrl ?? '',
    name: user?.name ?? '',
    options: [
      {
        label: 'Meu perfil',
        icon: 'User',
        href: `${Constants.portalUrl}/perfil/${user?.id}`,
        target: '_self',
      },
      {
        label: 'Sair da conta',
        icon: 'Logout',
        target: '_self',
        isDestructive: true,
        onClick: () => dispatch(logout()),
      },
    ],
  };

  return (
    <TopMenuPrimitive>
      <TopMenuPrimitive.Brand alt="logo" src={schoolBrand?.logoUrl} href={`${Constants.portalUrl}/home`} />

      <MenuUtilities>
        <MenuUtilities.Items options={utilityOptions} />

        <MenuUtilities.Avatar {...profile} />
      </MenuUtilities>
    </TopMenuPrimitive>
  );
};

export default TopMenu;
